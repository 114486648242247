const SkeletonLoader = (props) => {
  return (
    <div
      {...props}
      className="w-full max-w-7xl flex flex-col items-center justify-between mx-auto gap-12 py-12 px-4"
    >
      <div className="flex flex-col justify-between gap-8 lg:gap-12 w-full">
        <div className="animate-pulse flex flex-col items-center p-8 gap-8 rounded-lg bg-slate-100 w-full h-60">
          <div className="animate-pulse rounded-md bg-slate-200 w-32 h-3" />
          <div className="w-full flex justify-between items-center gap-4">
            <div className="animate-pulse rounded-md bg-slate-200 w-1/3 h-32" />
            <div className="animate-pulse rounded-md bg-slate-200 w-1/3 h-32" />
            <div className="animate-pulse rounded-md bg-slate-200 w-1/3 h-32" />
          </div>
        </div>
        <div className="animate-pulse flex items-start justify-center gap-8 p-8 rounded-lg bg-slate-100 w-full h-96 py-6 px-8">
          <div className="animate-pulse rounded-md bg-slate-200 w-36 h-3" />
        </div>
        <div className="animate-pulse flex flex-col items-center p-8 gap-8 rounded-lg bg-slate-100 w-full h-44">
          <div className="w-full flex flex-col justify-between items-center gap-4">
            <div className="animate-pulse rounded-md bg-slate-200 w-full h-12" />
            <div className="animate-pulse rounded-md bg-slate-200 w-full h-12" />
          </div>
        </div>
      </div>
    </div>
  );
};

SkeletonLoader.metadata = {
  name: "Daniel Eslava", // My name
  github: "Eslavadev", // Github username
  description: "Generic Chat", // Little tagline
  filename: "Chat", // filename of your loader
};

export default SkeletonLoader;
