import * as React from "react";
import axios from "axios";
import ACTIONS from "../actions";
import {
  REACT_APP_ETH_API_BASE_URL,
  REACT_APP_CRYPTO_API_BASE_URL,
  REACT_APP_CRYPTO_API_KEY,
} from "../../config";
import { convertETH, naiveDecimalRound } from "../../utils/utilities";

const { DATA_LOADING, SET_ETH_DATA, SET_ETH_DATA_HIGHLIGHT } = ACTIONS;

export const AppContext = React.createContext();

function useApp() {
  const context = React.useContext(AppContext);

  const [appState, dispatch] = context;

  //setup axios defaults for subsequent requests
  axios.defaults.headers.post["Content-Type"] = "application/json";

  const formatETHData = async (array, crypotExchangeRate) => {
    let updatedData = await Promise.all(
      array.map(async (item, i) => {
        const updatedFee = await convertETH(item.fee);
        return {
          ETH: updatedFee,
          USD: Number(updatedFee * crypotExchangeRate.USD),
          date: item.receivedAt,
          id: item.hash,
        };
      })
    );
    return updatedData;
  };

  const calculateETHHighlight = (array, crypotExchangeRate) => {
    // Calculate low, average and high fee
    const fees = array.map((item) => item.ETH);
    const nonZeroFees = fees.filter((fee) => fee !== 0);
    const totalFees = nonZeroFees.reduce((a, b) => a + b, 0);
    const averageFee = totalFees / nonZeroFees.length;
    const lowFee = Math.min(...nonZeroFees);
    const highFee = Math.max(...nonZeroFees);

    return {
      low: {
        ETH: lowFee,
        USD: naiveDecimalRound(Number(lowFee * crypotExchangeRate.USD)),
      },
      average: {
        ETH: averageFee,
        USD: naiveDecimalRound(Number(averageFee * crypotExchangeRate.USD)),
      },
      high: {
        ETH: highFee,
        USD: naiveDecimalRound(Number(highFee * crypotExchangeRate.USD)),
      },
    };
  };

  const getCryptoExchangeRate = async (cryptoName, temporal) => {
    if (temporal) {
      return { USD: 1800.12 };
    }
    const url = `${REACT_APP_CRYPTO_API_BASE_URL}/v2/cryptocurrency/quotes/latest?symbol=${cryptoName}`;
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "X-CMC_PRO_API_KEY": REACT_APP_CRYPTO_API_KEY,
    };
    const { data } = await axios.get(url, { headers });
    return { USD: data?.data?.ETH[0]?.quote?.USD?.price };
  };

  const getEthData = async (isRefreshing) => {
    const limit = 100;
    const totalItems = 1000;
    let itemsRetrieved = 0;
    const urlBase = `${REACT_APP_ETH_API_BASE_URL}/transactions?limit=${limit}&direction=older&from=`;

    if (!isRefreshing) dataLoading(true);

    try {
      const crypotExchangeRate = await getCryptoExchangeRate("ETH", true);
      let updatedData = [];
      let calculatedData = [];

      while (itemsRetrieved < totalItems) {
        const from = itemsRetrieved + 1;
        const url = `${urlBase}${from}`;
        const { data } = await axios.get(url);
        updatedData = updatedData.concat(await formatETHData(data.items, crypotExchangeRate));
        calculatedData = await calculateETHHighlight(updatedData, crypotExchangeRate);
        itemsRetrieved += limit;
      }

      updateEthData(updatedData);
      updateEthDataHighlight(calculatedData);
      dataLoading(false);
    } catch (err) {
      dataLoading(false);
    }
  };

  React.useEffect(() => {
    dataLoading(true);
    getEthData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dataLoading = (status) => {
    dispatch({
      type: DATA_LOADING,
      payload: status,
    });
  };

  const updateEthData = (data) => {
    dispatch({
      type: SET_ETH_DATA,
      payload: data,
    });
  };

  const updateEthDataHighlight = (data) => {
    dispatch({
      type: SET_ETH_DATA_HIGHLIGHT,
      payload: data,
    });
  };

  return {
    appState,
    dispatch,
    dataLoading,
    getEthData,
  };
}

export default useApp;
