import React, { useState, useEffect } from "react";
import { formatDate, naiveDecimalRound } from "../../utils/utilities";
import { Timer, TrendingDown, TrendingUp, BarChart2 } from "lucide-react";

export default function TopETH({
  ethDataHighlight,
  ethData,
  getEthData,
  children,
}) {
  const [timerCountdown, setTimerCountdown] = useState(60 * 1); // in seconds

  const getLastObjectFromETHData = (number) => {
    const arrOfObjs = Object.values(ethData);
    arrOfObjs.sort((a, b) => new Date(b.date) - new Date(a.date));
    const result = arrOfObjs.slice(0, number);
    return result;
  };

  const formattedETHResult = getLastObjectFromETHData(10);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimerCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (timerCountdown === 0) {
      setTimerCountdown(60 * 1);
      getEthData(true); // refresh data
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timerCountdown]);

  return (
    <div className="bg-white overflow-hidden rounded-xl border border-slate-50">
      <div className="py-8 px-6">
        <div className="flex flex-col gap-8">
          <div className="text-slate-500 w-full flex justify-center items-center gap-2">
            <Timer size={18} />
            <p className="text-center -mb-[2px] text-sm text-slate-500 font-medium">
              Next update in{" "}
              <span className="font-bold text-primary"> {timerCountdown} </span>{" "}
              s
            </p>
          </div>

          <div className="w-full flex sm:flex-row flex-col gap-2">
            <div className="bg-green-50 border border-green-200 w-full flex items-center justify-between rounded-lg p-4">
              <div className="flex flex-col gap-0 text-green-700">
                <span className="block font-semibold">$</span>
                <span className="block text-3xl font-bold">
                  {naiveDecimalRound(
                    Number(ethDataHighlight.low.USD),
                    2
                  ).toLocaleString()}
                </span>
              </div>
              <div className="text-green-900 flex flex-col gap-2 items-center justify-center">
                <TrendingDown size={48} strokeWidth={1} />
                <span className="bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded-full">
                  Low
                </span>
              </div>
            </div>

            <div className="bg-yellow-50 border border-yellow-200 w-full flex items-center justify-between rounded-lg p-4">
              <div className="flex flex-col gap-0 text-yellow-700">
                <span className="block font-semibold">$</span>
                <span className="block text-3xl font-bold">
                  {naiveDecimalRound(
                    Number(ethDataHighlight.average.USD),
                    2
                  ).toLocaleString()}
                </span>
              </div>
              <div className="text-yellow-900 flex flex-col gap-2 items-center justify-center">
                <BarChart2 size={48} strokeWidth={1} />
                <span className="bg-yellow-100 text-yellow-800 text-xs font-medium px-2.5 py-0.5 rounded-full">
                  Average
                </span>
              </div>
            </div>

            <div className="bg-red-50 border border-red-200 w-full flex items-center justify-between rounded-lg p-4">
              <div className="flex flex-col gap-0 text-red-700">
                <span className="block font-semibold">$</span>
                <span className="block text-3xl font-bold">
                  {naiveDecimalRound(
                    Number(ethDataHighlight.high.USD),
                    2
                  ).toLocaleString()}
                </span>
              </div>
              <div className="text-red-900 flex flex-col gap-2 items-center justify-center">
                <TrendingUp size={48} strokeWidth={1} />
                <span className="bg-red-100 text-red-800 text-xs font-medium px-2.5 py-0.5 rounded-full">
                  High
                </span>
              </div>
            </div>
          </div>
          {children}
          <div className="relative overflow-x-auto shadow-sm sm:rounded-lg">
            <table className="w-full text-sm text-left text-slate-500">
              <thead className="text-sm text-slate-700 uppercase bg-slate-50">
                <tr>
                  <th className="px-6 py-6">ETH</th>
                  <th className="px-6 py-6">USD</th>
                  <th className="px-6 py-6">Date</th>
                </tr>
              </thead>
              <tbody className="">
                {formattedETHResult.map((obj) => (
                  <tr
                    className="bg-white border-b even:bg-slate-50 last:border-b-0"
                    key={obj.id}
                  >
                    <th className="px-6 py-4 font-medium text-slate-900 whitespace-nowrap">
                      {obj.ETH}
                    </th>
                    <td className="px-6 py-4">
                      {naiveDecimalRound(Number(obj.USD)).toLocaleString()}
                    </td>
                    <td className="px-6 py-4">{formatDate(obj.date)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
