import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { formatDate } from "../../utils/utilities";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "top",
    },
    // title: {
    //   display: true,
    //   text: "Top 7 ETH",
    // },
  },
};

export default function TopETHChart({ ethData }) {
  const getLastObjectFromETHData = () => {
    const arrOfObjs = Object.values(ethData);
    arrOfObjs.sort((a, b) => new Date(b.date) - new Date(a.date));
    const result = arrOfObjs;
    return result;
  };

  const formattedETHResult = getLastObjectFromETHData();

  const labels = formattedETHResult.map(
    (item) => formatDate(item.date).split(",")[0]
  );

  const data = {
    labels,
    datasets: [
      {
        fill: true,
        label: "Last 1000 Transaction Fees (USD)",
        data: formattedETHResult.map((obj) => obj.USD.toLocaleString()),
        //data: Array.from({ length: 4000 }, () => Math.floor(Math.random() * 100)),
        borderColor: "rgb(0, 162, 235)",
        backgroundColor: "rgba(9, 162, 235, 0.5)",
      },
    ],
  };

  return (
    <div className="h-96 w-full px-4 py-8 border border-slate-100 rounded-xl bg-white">
      <Line options={options} data={data} />
    </div>
  );
}
