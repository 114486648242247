import React from "react";
import banner from './../../images/banner.png'; 

export default function Ads({ type = "image" }) {
  return (
    <>
      {type === "image" ? (
        <div className="w-50 h-48 bg-slate-100 overflow-hidden rounded-lg" style={{opacity: 0.9}}>
          <a href="https://payhip.com/bamboriz"  className="w-full h-full" target="_blank" rel="noreferrer">
            <img
              src={banner}
              alt=""
              className="w-full h-full object-cover"
            />
          </a>
        </div>
      ) : (
        <div className="w-full p-4 text-blue-800 border border-blue-300 rounded-lg bg-blue-50 overflow-hidden">
          {/* <div className="flex items-center">
            <svg
              className="flex-shrink-0 w-4 h-4 mr-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
            </svg>
            <span className="sr-only">Info</span>
            <h3 className="text-lg font-medium">ADS Example</h3>
          </div> */}
          <div className="mt-2 mb-4 text-sm">
            {/* <strong>Be a Multi-Chain Pro. </strong> */}
            {/* Elevate your blockchain expertise with our "Zero to Hero Algorand Ecosystem Onboarding Course" 
            and conquer the multi-chain future. */}
              Discover real-time transaction fees across various blockchains effortlessly with <a href="https://chat.openai.com/g/g-cgd2m4NfT-cryptogashub" target="_blank" rel="noreferrer"><strong>CryptoGasHub</strong></a>, the powerful <strong>GPT</strong> that provides instant and accurate responses to your gas queries.
          </div>
          <div className="flex">
            <a
              href="https://chat.openai.com/g/g-cgd2m4NfT-cryptogashub"
              target="_blank"
              rel="noreferrer"
              className="text-white bg-blue-800 hover:bg-blue-900 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center"
            >
              <svg
                className="-ml-0.5 mr-2 h-3 w-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 14"
              >
                <path d="M10 0C4.612 0 0 5.336 0 7c0 1.742 3.546 7 10 7 6.454 0 10-5.258 10-7 0-1.664-4.612-7-10-7Zm0 10a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z" />
              </svg>
              Try Now
            </a>
          </div>
        </div>
      )}
    </>
  );
}
