const BigNumber = require("bignumber.js");
// const ethers = require("ethers");

export const formatDate = (dateString) => {
  const dateObj = new Date(dateString);
  const options = {
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  const formattedDate = dateObj.toLocaleDateString("en-US", options);
  return formattedDate;
};

export const convertETH2 = (value) => {
  let num = new BigNumber(value);
  let denom = new BigNumber(10).pow(16);
  let ans = num.dividedBy(denom).toNumber();
  return ans;
};

export const convertETH = (value) => {
  let num = new BigNumber(value);
  let ans = Number(num) / 1e18;
  return ans;
};

export const naiveDecimalRound = (num, decimalPlaces = 2) => {
  var p = Math.pow(10, decimalPlaces);
  return Math.round(num * p) / p;
};
