import * as React from "react";
import appReducer from "../reducers/AppReducer.js";
import { AppContext } from "../contexts/AppContext.js";
import { REACT_APP_NAME } from "../../config";

function AppProvider({ children }) {
  const [state, dispatch] = React.useReducer(appReducer, {
    appName: REACT_APP_NAME,
    ethData: {},
    ethDataHighlight: {
      low: { ETH: 0, USD: 0 },
      average: { ETH: 0, USD: 0 },
      high: { ETH: 0, USD: 0 },
    },
    dataLoading: false,
  });

  const value = [state, dispatch];

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

export default AppProvider;
