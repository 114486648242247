import React from "react";
import styles from "./style.module.scss";
import Navbar from "../../components/Navbar/Navbar";
import TopETHTable from "../../components/TopCryptos/TopETHTable";
import TopETHChart from "../../components/TopCryptos/TopETHChart";
import SkeletonLoader from "../../components/SkeletonLoader/SkeletonLoader";
import useApp from "../../store/contexts/AppContext";
import Ads from "../../components/AdsExample/Ads";
// import SyncSwapIframe from "../../components/Iframes/SyncSwapIframe";

export default function Dashboard() {
  const {
    appState: { dataLoading, ethData, ethDataHighlight },
    getEthData,
  } = useApp();

  return (
    <div className="pt-16 min-h-screen">
      <Navbar />

      <div className="flex items-center justify-center w-full mt-12">
        <h1 className="inline-block text-xl sm:text-3xl font-extrabold text-slate-800 tracking-tight text-center">
          <span className="bg-gradient-to-r from-primary via-light-blue-400 to-blue-400 text-transparent bg-clip-text">
            Track
          </span>{" "}
          Gas Fees,{" "}
          <span className="bg-gradient-to-r from-primary via-light-blue-400 to-blue-400 text-transparent bg-clip-text">
            Optimize
          </span>{" "}
          Transactions, Stay{" "}
          <span className="bg-gradient-to-r from-primary via-light-blue-400 to-blue-400 text-transparent bg-clip-text">
            Ahead
          </span>
        </h1>
      </div>

      {dataLoading ? (
        <div className="">
          <SkeletonLoader />
        </div>
      ) : (
        <div
          className={`${styles.container} relative flex justify-center items-center w-full bg-white`}
        >
          <img
            className="hidden lg:block absolute top-96 right-0 blur-sm opacity-10 w-[32rem]"
            src="/illustrations/one.svg"
            alt="icon"
          />
          <img
            className="hidden lg:block absolute -top-48 left-0 blur-sm opacity-10 w-[32rem]"
            src="/illustrations/two.svg"
            alt="icon"
          />
          <div className="z-40 w-full max-w-7xl flex flex-col items-center justify-center py-10 px-4 gap-12">
            <div className="w-full flex flex-col gap-6">
            <Ads type="text"/>
              <TopETHTable
                ethDataHighlight={ethDataHighlight}
                ethData={ethData}
                getEthData={getEthData}
              >
                <TopETHChart ethData={ethData} />
              </TopETHTable>
              {/* <Ads type="text"/> */}
            </div>
            {/* <div className={styles.innerBody}>
            <SyncSwapIframe />
          </div> */}
          </div>
        </div>
      )}
      <footer className="flex justify-center items-center w-full border-t border-slate-100 bg-white">
        <div className="w-full max-w-7xl flex flex-col items-center justify-center py-6 px-4 gap-4">
          <p className="text-center text-sm text-slate-500">
            <span className="font-bold text-slate-900">zkGas Tracker</span> is
            now part of the{" "}
            <a
              href="https://ecosystem.zksync.io/"
              className="text-primary font-medium hover:underline hover:underline-offset-4"
            >
              zkSync Era
            </a>{" "}
            ecosystem. Thanks for your trust as we continue to integrate more
            chains!
          </p>
          <span className="w-96 h-[1px] block bg-slate-100 my-1"></span>
          <p className="text-slate-500 text-sm text-center">
            Made with 💜 by{" "}
            <a
              href="https://twitter.com/iamtechonda"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary font-bold hover:underline hover:underline-offset-4"
            >
              @iamtechonda
            </a>
          </p>
        </div>
      </footer>
    </div>
  );
}
