import React from "react";
import { REACT_APP_NAME } from "../../config";
import { Twitter } from "lucide-react";

export default function Navbar() {
  return (
    <header className="flex justify-center items-center fixed top-0 w-full border-b border-slate-100 bg-white z-50">
      <div className="w-full max-w-7xl flex items-center justify-between py-1 px-4 gap-4">
        <a href="/" className="text-primary  flex items-center gap-2 py-4 px-2">
          <svg
            width="29"
            height="24"
            viewBox="0 0 29 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="11.571"
              width="16.364"
              height="16.364"
              transform="rotate(45 11.571 0)"
              fill="#7D67FF"
            />
            <rect
              x="17.4148"
              y="0.707107"
              width="15.364"
              height="15.364"
              transform="rotate(45 17.4148 0.707107)"
              stroke="#352E5B"
            />
          </svg>
          <h1 className="text-slate-900 font-semibold">{REACT_APP_NAME}</h1>
        </a>
        {/* <a
          href="https://twitter.com/iamtechonda"
          target="_blank"
          rel="noopener noreferrer"
          className="text-primary hover:bg-slate-100 rounded-full border border-indigo-200 bg-slate-50 p-2"
        >
          <Twitter size={18} strokeWidth={1.75} />
        </a> */}
        <a class="twitter-share-button" target="_blank"
          href="https://twitter.com/intent/tweet?text=Tracking%20gas%20like%20a%20pro%20with%20%40iamtechonda%27s%20tracker%21%20%20%0A%0ASay%20goodbye%20to%20guesswork%20and%20hello%20to%20precise%20gas%20insights.%20%0A%0AExplore%20it%20now%20at%20zkgastracker.com%20and%20take%20control%20of%20your%20transactions." rel="noreferrer">
          <Twitter size={18} strokeWidth={1.75} />
        </a>
        {/* <div className="text-slate-500 font-medium">
          ETH Price <span className="text-red-500 font-bold">$12,400</span>
        </div> */}
      </div>
    </header>
  );
}
