import ACTIONS from "../actions";
const { DATA_LOADING, SET_ETH_DATA, SET_ETH_DATA_HIGHLIGHT } = ACTIONS;

export default function appReducer(state, action) {
  switch (action.type) {
    case DATA_LOADING:
      return {
        ...state,
        dataLoading: action.payload,
      };
    case SET_ETH_DATA:
      let obj = {};
      action.payload.forEach((data) => {
        obj[data.id] = data;
      });
      const updatedETHs = {
        ...obj,
      };
      return { ...state, ethData: updatedETHs };
    case SET_ETH_DATA_HIGHLIGHT:
      return {
        ...state,
        ethDataHighlight: action.payload,
      };
    default:
      return state;
  }
}
